import { FC } from 'react';
import IconProps from './IconProps';

const StatusCircleIcon: FC<IconProps> = (props) => {
  const { onClick, className } = props;
  const cursor = onClick ? 'cursor-pointer' : '';

  return (
    <svg
      onClick={onClick}
      className={`inline flex-shrink-0 ${cursor} ${className}`}
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="7.5" cy="7.5" r="6.5" fill="currentColor" fillOpacity="0.3" stroke="currentColor" strokeWidth="2" />
    </svg>
  );
};

export default StatusCircleIcon;
